.strategy-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  height: fit-content;
}

.strategy-card-wrapper {
  display: inline-grid !important;
  grid-template-columns: auto auto auto;
  margin: 20px;
}

.strategy-row {
  background: #FAFAFA;
  border-radius: 20px;
  padding: 5px;
}

@include dark {
  .strategy-row {
    background: #1e2128;
  }
}

.strategy-col {
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.strategy-span {
  display: inline-block;
  vertical-align: middle;
  line-height: 50px;
}