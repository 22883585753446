.custom-radio {
  display: flex;
  align-items: center;
  background-color: $gray-dark-800;
  border: 1px solid $gray-dark-700;
  border-radius: 0.4rem;
  padding: 0.5rem;
}

.custom-radio:has(input:checked) {
  border-color: #7A7FDC !important;
}